import React, {Suspense} from "react";
import {useRoutes} from "react-router-dom";

//components


//home
const Home = React.lazy(() => import('../pages/Home'));


//auth
// const Login = React.lazy(() => import('../pages/auth/Login'))
// const SignUp = React.lazy(() => import('../pages/auth/Signin'))

const loading = () => <div className=""></div>;

type LoadingComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadingComponentProps) => {
    return (
        <Suspense fallback={loading()}>
            <Component/>
        </Suspense>
    )
};

const AllRoutes = () => {
    return useRoutes([
      {
        //root route
        path: "/",
        element: <LoadComponent component={Home} />,
      }
    ]);
}

export default AllRoutes;
